import React from 'react'
import {Link} from "gatsby"

import CardJob from "../styled-components/cards/SpeakerCard/CardJob"
import CardName from "../styled-components/cards/SpeakerCard/CardName"
import CardImg from "../styled-components/cards/SpeakerCard/CardImg"
import CardWrapper from "../styled-components/cards/SpeakerCard/CardWrapper"

const SpeakerCard = ({avatar, name, position, slug}) => {
    return (
        <Link to={slug ? ("/speakers/" + slug) : "/"}>
            <CardWrapper>
                {avatar ? <CardImg src={avatar} alt={name}/> : null}
                <CardName>{name}</CardName>
                <CardJob>{position}</CardJob>
            </CardWrapper>
        </Link>
    )
}

export default SpeakerCard