import styled from "styled-components"

const CardJob = styled.p`
  margin-top: -4px;
  margin-bottom: 8px;
  color: #706b81;
  font-size: 13px;
  line-height: 20px;
`

export default CardJob