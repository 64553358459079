import styled from "styled-components"

const CardName = styled.h2`
  margin-top: 10px;
  color: black;
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
`

export default CardName