import styled from "styled-components"

const CardWrapper = styled.div`
  grid-gap: 10px;
  grid-template-rows: 3fr;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export default CardWrapper