import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../components/layouts/Layout";
import SpeakerCard from "../components/cards/SpeakerCard";
import Seo from "../components/main/SEO";

import Col from "../components/styled-components/wrappers/Col";
import H2 from "../components/styled-components/typography/H2";
import LinkButton from "../components/styled-components/button/LinkButton";
import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import Divider from "../components/styled-components/divider/Divider";

const Speakers = () => {

    const data = useStaticQuery(graphql`
    {
      inactiveEvents: allStrapiBlackowlEvents(filter: {blackowl_meetup: {isActive: {eq: false}}}) {
        inactive: distinct(field: speaker___slug)
      }
      activeEvents: allStrapiBlackowlEvents(filter: {blackowl_meetup: {isActive: {eq: true}}}) {
        active: distinct(field: speaker___slug)
        active_additional: distinct(field: additional_speakers___slug)
      }
      allSpeakers: allStrapiBlackowlSpeakers(sort: {fields: name, order: ASC}) {
        nodes {
          slug
          name
          position
          avatar {
            url
          }
        }
      }
    }
  `)

    const {inactiveEvents: {inactive}} = data;
    const {activeEvents: {active, active_additional}} = data;
    const {allSpeakers: {nodes}} = data;

    const notActiveNow = inactive.filter(f => !active.includes(f));

    const activeSpeakers = nodes.filter(f => active.includes(f.slug) || active_additional.includes(f.slug));
    const inactiveSpeakers = nodes.filter(f => notActiveNow.includes(f.slug));

    return (
        <Layout title={"Előadók"}>
            <Seo title={"Balasys Blackowl - Előadók"} description={"A Balasys Blackowl előadók oldala."}
                 keywords={"balasys, blackowl, előadók"} url={"/speakers/"}/>
            <SectionLayoutGrid>
                <Col full marginBottom="small">
                    <H2 medium>Aktuális előadóink</H2>
                    <Divider fullOrange/>
                </Col>
                {activeSpeakers.length !== 0 && activeSpeakers.map((item) => (
                        <Col key={item.slug} col3 marginBottom="small">
                            <SpeakerCard
                                name={item.name}
                                avatar={item.avatar.url}
                                position={item.position}
                                slug={item.slug}
                            />
                        </Col>
                ))}
                {activeSpeakers.length === 0 &&
                <Col col3 marginBottom="small">
                    <SpeakerCard
                        name={"Hamarosan..."}
                        position={"Hamarosan..."}
                    />
                </Col>
                }
                {activeSpeakers.length !== 0 &&
                <Col full center marginBottom="medium">
                    <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                </Col>
                }
                <Col full marginBottom="medium">
                    <Divider full/>
                </Col>
                <Col full marginBottom="small">
                    <H2 medium>Korábbi előadóink</H2>
                    <Divider fullOrange/>
                </Col>
                {inactiveSpeakers.map((item) => (
                    <Col col3 key={item.slug} marginBottom="small">
                        <SpeakerCard
                            name={item.name}
                            avatar={item.avatar.url}
                            position={item.position}
                            slug={item.slug}
                        />
                    </Col>
                ))}
            </SectionLayoutGrid>
        </Layout>
    )
}

export default Speakers